import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    token: null,
    error: null,
    error2: null,
    regerror: null,
    vererror: null,
    loading: false,
    loading2: false,
    verloading: false,
    verify: false,
    hpRedirect: false,
    warning: null,
    redirectVerify: false,
    successMessage: null,
    wallet: null,
    promoWallet: null,
    ticketMessage: null,
    ticketMessageAF: null,
    ticketMessageL7: null,
    ticketMessageBa: null,
    ticketMessageSc: null,
    statusAF: null,
    statusL7: null,
    statusBa: null,
    statusSc: null,
    pick3Message: null,
    pick3status: null,
    af_maxPrize: null,
    af_prizecValues: null,
    afid: null,
    l7_maxPrize: null,
    l7_prizecValues: null,
    l7_ticketPrice: null,
    l7id: null,
    scid: null,
    sc_maxPrize: null,
    sc_prizecValues: null,
    sc_ticketPrice: null,
    ae_maxPrize: null,
    ae_prizeValues: null,
    ae_ticketPrice: null,
    aeid: null,
    unplayed_scratch: null,
    sw_maxPrize: null,
    sw_prizeValues: null,
    sw_ticketPrice: null,
    brid: null,
    br_maxPrize: null,
    br_prizeValues: null,
    br_ticketPrice: null,
    swid: null,
    unplayed_spinwin: null,
    unplayed_africanExplorer: null,
    unplayed_brokenRecord: null,
    unplayed_bingo: null,
    unplayed_sport: null,
    l7play: null,
    session_id: null,
    duplicateSession:false, //used to display the popup when another session exists
    extAuthentication:false, //it can be removed
    stompConnected:false
};
const updateWallet = (state, action) => {
    return updateObject( state, {
        wallet: action.wallet,
        promoWallet: action.promoWallet,
    } );
};
const authStart = ( state, action ) => {
    return updateObject( state, { error: null, loading: true } );
};

const updateStomp = (state, action ) => {
    console.log("dddddddddddd: ",action)
    return updateObject( state, {  stompConnected: action.stompConnected } );
};

const getDuplicateSession = ( state, action ) => {
    return updateObject( state, { duplicateSession: action.duplicateSession } );
};
const externalAuth= ( state, action ) => {
    return updateObject( state, { extAuthentication: action.extAuthentication } );
};
const authStart2 = ( state, action ) => {
    return updateObject( state, { error2: null, loading2: true } );
};
const regStart = ( state, action ) => {
    return updateObject( state, { regerror: null, regloading: true } );
};
const updateMessage = ( state, action ) => {
    return updateObject( state, { ticketMessage: action.ticketMessage, status: action.status } );
};
const updateMessageAF = ( state, action ) => {
    return updateObject( state, { ticketMessageAF: action.ticketMessageAF, statusAF: action.statusAF } );
};
const updateMessageL7 = ( state, action ) => {
    return updateObject( state, { ticketMessageL7: action.ticketMessageL7, statusL7: action.statusL7 } );
};
const updateMessageBa = ( state, action ) => {
    return updateObject( state, { ticketMessageBa: action.ticketMessageBa, statusBa: action.statusBa } );
};
const updateMessageSc = ( state, action ) => {
    return updateObject( state, { ticketMessageSc: action.ticketMessageSc, statusSc: action.statusSc } );
};
const updateMessageAE = ( state, action ) => {
    return updateObject( state, { ticketMessageAE: action.ticketMessageAE, statusAE: action.statusAE } );
};
const updateMessageBR = ( state, action ) => {
    return updateObject( state, { ticketMessageBR: action.ticketMessageBR, statusBR: action.statusBR } );
};
const updateMessageSW = ( state, action ) => {
    return updateObject( state, { ticketMessageSW: action.ticketMessageSW, statusSW: action.statusSW } );
};
const updatePickMessage = ( state, action ) => {
    return updateObject( state, { pick3Message: action.pick3Message, pick3status: action.status } );
};
const displayWarning = ( state, action ) => {
    return updateObject( state, { warning: action.message, loading: false } );
};
const displaySuccess = ( state, action ) => {
    return updateObject( state, { successMessage: action.message, loading: false } );
};
const authSuccess = (state, action) => {
    return updateObject( state, {
        token: action.token,
        error: null,
        loading: false,
        loading2: false,
     } );
};
const verifySuccess = (state, action) => {
    return updateObject( state, {
        token: action.token,
        error: null,
        loading: false,
        hpRedirect: true
    } );
};

const redirectVerify = (state, action) => {
    return updateObject( state, {
        token: null,
        error: null,
        redirectVerify: action.redirectVerify,
        loading:false
    } );
};
const registerSuccess  = (state, action) => {
    return updateObject( state, {
        verify: true,
        loading: false,
    } );
};
const verifyMsisdn  = (state, action) => {
    return updateObject( state, {
        token: action.token,
        verifyloading: false,
        hpRedirect: true
    } );
};

const authRegister = (state, action) => {
    return updateObject( state, {
        regerror: null,
        regloading: false
    } );
};

const authFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};
const authFail2 = (state, action) => {
    return updateObject( state, {
        error2: action.error2,
        loading2: false
    });
};
const authRegFail = (state, action) => {
    return updateObject( state, {
        regerror: action.regerror,
        regloading: false
    });
};
const authVerStart = (state, action) => {
    return updateObject( state, {
        verloading: true
    });
};
const updateSessionId = (state, action) => {
    console.log('session red', action)
    return updateObject( state, {
        session_id: action.session_id
    });
};
const authVerFail = (state, action) => {
    return updateObject( state, {
        vererror: action.vererror,
        verloading: false
    });
};
const authLogout = (state, action) => {
    return updateObject(state, { token: null });
};
const setScratch = (state, action) => {
    return updateObject(state, {
        afid: action.afid,
        af_maxPrize: action.af_maxPrize,
        af_prizeValues: action.af_prizeValues,
        af_ticketPrice: action.af_ticketPrice

    });
};
const setAfricanExplorer = (state, action) => {
    return updateObject(state, {
        aeid: action.aeid,
        ae_maxPrize: action.ae_maxPrize,
        ae_prizeValues: action.ae_prizeValues,
        ae_ticketPrice: action.ae_ticketPrice
    });

};
const setLucky7 = (state, action) => {
    return updateObject(state, {
        l7id: action.l7id,
        l7_maxPrize: action.l7_maxPrize,
        l7_prizeValues: action.l7_prizeValues,
        l7_ticketPrice: action.l7_ticketPrice
    });

};
const setBingo = (state, action) => {
    return updateObject(state, {
        baid: action.baid,
        ba_maxPrize: action.ba_maxPrize,
        ba_prizeValues: action.ba_prizeValues,
        ba_ticketPrice: action.ba_ticketPrice
    });

};
const setSport = (state, action) => {
    return updateObject(state, {
        scid: action.scid,
        sc_maxPrize: action.sc_maxPrize,
        sc_prizeValues: action.sc_prizeValues,
        sc_ticketPrice: action.sc_ticketPrice
    });

};
const setBrokenRecord = (state, action) => {
    return updateObject(state, {
        brid: action.brid,
        br_maxPrize: action.br_maxPrize,
        br_prizeValues: action.br_prizeValues,
        br_ticketPrice: action.br_ticketPrice
    });

};
const setSpinWin = (state, action) => {
    return updateObject(state, {
        swid: action.swid,
        sw_maxPrize: action.sw_maxPrize,
        sw_prizeValues: action.sw_prizeValues,
        sw_ticketPrice: action.sw_ticketPrice
    });

};
const setUnplayed = (state, action) => {
    return updateObject(state, {
        unplayed_scratch: action.unplayed_scratch
    });

};
const setUnplayedAfricanExplorer = (state, action) => {
    return updateObject(state, {
        unplayed_africanExplorer: action.unplayed_africanExplorer
    });
};
const setUnplayedLucky7 = (state, action) => {
    return updateObject(state, {
        unplayed_lucky7: action.unplayed_lucky7
    });
};
const sendPlayNot = (state, action) => {
    return updateObject(state, {
        l7play : action.l7play
    });
};
const setUnplayedSpinWin = (state, action) => {
    return updateObject(state, {
        unplayed_spinwin: action.unplayed_spinwin
    });
};
const setUnplayedBrokenRecord = (state, action) => {
    return updateObject(state, {
        unplayed_brokenRecord: action.unplayed_brokenRecord
    });
};
const setUnplayedBingo = (state, action) => {
    return updateObject(state, {
        unplayed_bingo: action.unplayed_bingo
    });
};
const setUnplayedSport = (state, action) => {
    return updateObject(state, {
        unplayed_sport: action.unplayed_sport
    });
};
const hideSpin = (state, action) => {
    return updateObject(state, {
        spinhidden: action.spinhidden
    });
};
const setUserMsisdn = (state, action) => {
    return updateObject(state, {
        userMsisdn: action.userMsisdn
    });
};
const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_START2: return authStart2(state, action);
        case actionTypes.VER_START: return authVerStart(state, action);
        case actionTypes.VER_FAIL: return authVerFail(state, action);
        case actionTypes.REG_START: return regStart(state, action);
        case actionTypes.AUTH_REGISTER: return authRegister(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_FAIL2: return authFail2(state, action);
        case actionTypes.AUTH_REG_FAIL: return authRegFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.REGISTER_SUCCESS: return registerSuccess(state, action);
        case actionTypes.AUTH_VERIFY_MSISDN: return verifyMsisdn(state, action);
        case actionTypes.VERIFY_SUCCESS: return verifySuccess(state, action);
        case actionTypes.REDIRECT_VERIFY: return redirectVerify(state, action);
        case actionTypes.DISPLAY_WARNING: return displayWarning(state, action);
        case actionTypes.DISPLAY_SUCCESS: return displaySuccess(state, action);
        case actionTypes.UPDATE_WALLET: return updateWallet(state, action);
        case actionTypes.UPDATE_MESSAGE: return updateMessage(state, action);
        case actionTypes.UPDATE_MESSAGE_AF: return updateMessageAF(state, action);
        case actionTypes.UPDATE_MESSAGE_AE: return updateMessageAE(state, action);
        case actionTypes.UPDATE_MESSAGE_BR: return updateMessageBR(state, action);
        case actionTypes.UPDATE_MESSAGE_SW: return updateMessageSW(state, action);
        case actionTypes.UPDATE_MESSAGE_L7: return updateMessageL7(state, action);
        case actionTypes.UPDATE_MESSAGE_BA: return updateMessageBa(state, action);
        case actionTypes.UPDATE_MESSAGE_SC: return updateMessageSc(state, action);
        case actionTypes.UPDATE_PICK_MESSAGE: return updatePickMessage(state, action);
        case actionTypes.SET_SCRATCH: return setScratch(state, action);
        case actionTypes.SET_AFRICAN_EXPLORER: return setAfricanExplorer(state, action);
        case actionTypes.SET_SPIN_WIN: return setSpinWin(state, action);
        case actionTypes.SET_LUCKY7: return setLucky7(state, action);
        case actionTypes.SET_SPORT: return setSport(state, action);
        case actionTypes.SET_BINGO: return setBingo(state, action);
        case actionTypes.SET_BROKEN_RECORD: return setBrokenRecord(state, action);
        case actionTypes.SET_UNPLAYED: return setUnplayed(state, action);
        case actionTypes.SET_UNPLAYED_AFRICAN_EXPLORER: return setUnplayedAfricanExplorer(state, action);
        case actionTypes.SET_UNPLAYED_LUCKY7: return setUnplayedLucky7(state, action);
        case actionTypes.SET_UNPLAYED_SPORT: return setUnplayedSport(state, action);
        case actionTypes.SEND_PLAY_NOT: return sendPlayNot(state, action);
        case actionTypes.SET_UNPLAYED_SPIN_WIN: return setUnplayedSpinWin(state, action);
        case actionTypes.SET_UNPLAYED_BROKEN_RECORD: return setUnplayedBrokenRecord(state, action);
        case actionTypes.SET_UNPLAYED_BINGO: return setUnplayedBingo(state, action);
        case actionTypes.GET_DUPLICATE_SESSION: return getDuplicateSession(state, action);
        case actionTypes.EXT_AUTH: return externalAuth(state, action);
        case actionTypes.UPDATE_SESSION: return updateSessionId(state, action);
        case actionTypes.STOMP_CONNECTED: return updateStomp(state, action);
        case actionTypes.SET_USER_MSISDN: return setUserMsisdn(state, action);
        default:
            return state;
    }
};

export default reducer;