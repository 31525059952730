import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Header from '../../Components/NewHeader/Header';
import Footer from "../../Components/Footer/Footer";
import Input from '../../Components/UI/Input/Input';
import * as actions from '../../store/actions/index';
import { translate} from "react-i18next";
import ReactGA from "react-ga";

class Login extends Component {
    state = {
        redirect: null,
        controls: {
            username: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                },
                value: '',
                validation: {
                    required: true,
                },
                valid: false,
                touched: false,
                label: 'Pseudo'
            },
            password: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6
                },
                valid: false,
                touched: false,
                label: 'Mot de passe'
            },
        },
        loading: false
    };


    componentDidMount () {
        this.props.onTryAutoSignup();
        window.scrollTo(0,0);
        ReactGA.pageview('/login');
    }
    checkValidity ( value, rules ) {
        let isValid = true;
        if ( !rules ) {
            return true;
        }

        if ( rules.required ) {
            isValid = value.trim() !== '' && isValid;
        }

        if ( rules.minLength ) {
            isValid = value.length >= rules.minLength && isValid
        }

        if ( rules.maxLength ) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if ( rules.isEmail ) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test( value ) && isValid
        }

        if ( rules.isNumeric ) {
            const pattern = /^\d+$/;
            isValid = pattern.test( value ) && isValid
        }

        return isValid;
    }
    inputChangedHandler = ( event, controlName ) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
                valid: this.checkValidity( event.target.value, this.state.controls[controlName].validation ),
                touched: true
            }
        };
        this.setState( { controls: updatedControls } );
    }
    submitHandler = ( event ) => {
        event.preventDefault();
        this.props.onAuth( this.state.controls.username.value, this.state.controls.password.value);
    };
    onChange(value) {
        console.log("Captcha value:", value);
    }
    render() {
        const { t } = this.props;
        let redirect = null;
        // const { from } = this.props.location.state || { from: { pathname: '/' } }
        const queryString = require('query-string');
        const parsed = queryString.parse(this.props.location.search);
        if ( this.props.isAuthenticated ) {
            if (parsed.soccerpool == 1) {
                redirect = <Redirect to={{
                    pathname: '/soccer-pool-betting',
                }}
                />
            } else {
                redirect = <Redirect to={this.props.location.state && this.props.location.state.from || '/'}
                />;
            }
        } else if ( this.props.redirectVerify ) {
            redirect = <Redirect to={{
                pathname: '/verify',
            }}
            />
        }
        let warning = '';
        if (this.props.warning) {
            warning = (
                <div className="warning">
                    <i className="fa fa-exclamation-triangle"></i>
                    <span>{this.props.warning}</span>
                </div>
            );
        }
        let error = '';
        if (this.props.error) {
            error = (
                <div className="error">
                    <i className="fa fa-exclamation-triangle"></i>
                    <span>{this.props.error}</span>
                </div>
            );
        }
        const formElementsArray = [];
        for ( let key in this.state.controls ) {
            formElementsArray.push( {
                id: key,
                config: this.state.controls[key]
            } );
        }

        let form = formElementsArray.map( formElement => (
            <Input
                label={formElement.config.label}
                classes="inputfield"
                key={formElement.id}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                changed={( event ) => this.inputChangedHandler( event, formElement.id )} />
        ) );
        let loading = false;
        if (this.props.loading) {
             loading = <div className="loading-container"><i className="fa fa-spinner fa-spin"></i> </div>;
        }
        return (
            <div className="mojabet-website login">
                <Header/> {redirect}
                <section className="staticpages-content">
                    <div className="container-fluid content-container">
                        <div className="page-title-container">
                            <h1 className="page-title">COMPTE</h1>
                        </div>
                        <div className="form-registration login">
                            <h2 className="form-title">SE CONNECTER</h2>
                            <div className="form-container">
                                <form  onSubmit={this.submitHandler}>
                                    {form}
                                    {/*<div className="recaptcha">*/}
                                        {/*<ReCAPTCHA*/}
                                            {/*sitekey="6LeIQ6cUAAAAAO5l1qtEx1Jo3YzW_zxNhZ22Z82V"*/}
                                            {/*onChange={this.onChange}*/}
                                        {/*/>*/}
                                    {/*</div>*/}
                                    <button  className="submit-btn">{t("SE CONNECTER")}</button>
                                </form>
                            </div>
                            {loading}
                            {warning}
                            {error}
                        </div>
                    </div>
                </section>
                <Footer/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading2,
        error: state.auth.error2,
        isAuthenticated: state.auth.token !== null,
        warning: state.auth.warning,
        redirectVerify: state.auth.redirectVerify
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: ( username, password ) => dispatch( actions.auth( username, password ) ),
        onTryAutoSignup: () => dispatch( actions.authCheckState() )
    };
};

export default connect( mapStateToProps, mapDispatchToProps )(translate("translations")( Login ));