import React, { Component } from 'react';
import Header from '../../Components/NewHeader/Header';
import Footer from "../../Components/Footer/Footer";
import Hero from "../../Components/Hero/Hero";
import ContactHero from "../../assets/images/contact-hero.jpg";
import Spin from 'react-reveal/Spin';
import Swing from 'react-reveal/Swing';
import Input from "../../Components/UI/Input/Input";
import axios from "axios";
import { translate} from "react-i18next";
import ReactGA from "react-ga";

class ContactUs extends Component {
    state = {
        loading: false,
        message: null,
        status: null,
        controls: {
            fullname: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                },
                value: '',
                validation: {
                    required: true,
                },
                valid: false,
                touched: false,
                parentClass: 'fields-container half',
                label: "Nom Complet"
            },
            phone: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',

                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6
                },
                valid: false,
                touched: false,
                parentClass: 'fields-container half',
                label: "Numéro de téléphone"
            },
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6
                },
                valid: false,
                touched: false,
                parentClass: 'fields-container full',
                label: "Email",
            },
            message: {
                elementType: 'textarea',
                elementConfig: {
                    type: 'email',
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6
                },
                valid: false,
                touched: false,
                parentClass: 'fields-container full',
                label: "Message",
            }
        },
    };
    componentDidMount() {
        window.scrollTo(0, 0);
        ReactGA.pageview('/contactus');
    }

    checkValidity ( value, rules ) {
        let isValid = true;
        if ( !rules ) {
            return true;
        }

        if ( rules.required ) {
            isValid = value.trim() !== '' && isValid;
        }

        if ( rules.minLength ) {
            isValid = value.length >= rules.minLength && isValid
        }

        if ( rules.maxLength ) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if ( rules.isEmail ) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test( value ) && isValid
        }

        if ( rules.isNumeric ) {
            const pattern = /^\d+$/;
            isValid = pattern.test( value ) && isValid
        }

        return isValid;
    }
    inputChangedHandler = ( event, controlName ) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
                valid: this.checkValidity( event.target.value, this.state.controls[controlName].validation ),
                touched: true
            }
        };
        this.setState( { controls: updatedControls } );
    }
    submitHandler = ( event ) => {
        event.preventDefault();
        this.setState({
            loading: true,

        })
        const contactData = {
            fullname: this.state.controls.fullname.value,
            mobileNumber: this.state.controls.phone.value,
            email: this.state.controls.email.value,
            message: this.state.controls.message.value,
            lang:  localStorage.getItem('i18nextLng')
        };
        let url = '/web-api/contact-us-messages';
        this.setState( {
            loading: true,
        })
        axios.post(url, contactData)
            .then(response => {
                if (response.data.status === 1) {
                    this.setState({
                        message: response.data.message,
                        status: 1,
                        loading: false,
                    })
                } else if (response.data.status === 0) {
                    this.setState({
                        message: response.data.message,
                        status: 0,
                        loading: false
                    })
                }
            })
            .catch(err => {
                this.setState({
                    responseMessage: err.message,
                    status: 0,
                    loading: false
                })
            });

    }

    render() {
        const { t } = this.props;
        let message = '';
        if (this.state.status == 1) {
            message = <div className="success">
                <i className="fa fa-check"></i>
                <span>{this.state.message} </span>
            </div>
        } else if (this.state.status == 0) {
            message = <div className="error">
                <i className="fa fa-exclamation-triangle"></i>
                <span>{this.state.message}</span>
            </div>
        }
        const formElementsArray = [];
        for ( let key in this.state.controls ) {
            formElementsArray.push( {
                id: key,
                config: this.state.controls[key]
            } );
        }

        let form = formElementsArray.map( formElement => (
            <div className={formElement.config.parentClass} key={formElement.id}>
            <Input
                label={formElement.config.label}
                classes="input"
                key={formElement.id}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                changed={( event ) => this.inputChangedHandler( event, formElement.id )} />
            </div>
        ) );
        let loading = false;
        if (this.state.loading) {
            loading = <div className="loading-container blue" style={{marginTop: '30px'}}><i className="fa fa-spinner fa-spin"></i> </div>;
        }
        return (
            <div className="mojabet-website aboutus">
                <Header/>
                <Hero background={ContactHero} />
                <section className="staticpages-content">
                    <div className="container-fluid content-container">
                        <div className="page-title-container">
                            <h1 className="page-title">{t("NOUS CONTACTER")}</h1>
                        </div>
                        <div className="contact-container">
                            <div className={"contact-form"}>
                                <form onSubmit={this.submitHandler}>
                                    {form}
                                    <input type="submit" value={t("envoyer")}/>
                                </form>
                                {loading}
                                {message}
                            </div>
                        </div>
                        <div className="contact-details">
                            <div className="details-contact">
                                <div className="contact-channel">
                                    {/* <Zoom>
                                    <i className="fa fa-whatsapp"></i>
                                    </Zoom> */}
                                    <Swing>
                                    <i className="fa fa-phone"></i>
                                    </Swing>
                                </div>
                                <h3>{"Centre d’Appel"}</h3>
                                <span>Joignable aux numéros</span>
                                <span>99 00 et 27 21 59 99 00</span>
                                <span>Email: <a  href={'mailto:contact@lonaci.ci?'} className='linkto-label'>contact@lonaci.ci</a></span>
                                <span>Site Web: <a target='_blank' href="https://www.lonacionline.ci" className='linkto-label'>www.lonacionline.ci</a> </span>
                            </div>
                            <div className="details-contact">
                                <div className="contact-channel">
                                    <Spin>
                                    <i className="fa fa-clock-o"></i>
                                    </Spin>
                                </div>
                                <h3>{t("Heures d'ouverture")}</h3>
                                <span>Du lundi au vendredi de 7h30 à 16h30 </span>
                                {/* <span>Samedi: 02:00 am - 4:00 pm</span> */}
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>
            </div>
        );
    }
}

export default translate("translations")(ContactUs);