import React, { Component } from 'react';
import { Col, Row } from 'bootstrap-4-react';
import Header from '../../../Components/NewHeader/Header';
import Footer from "../../../Components/Footer/Footer";
import PickPlay from "./PickPlay";
import PickSideMenu from "./PickSideMenu";
import HowToPlay from "./HowToPlay";
import Purchases from "./Purchases";
import Winnings from "./Winnings";
import Winners from "./Winners";
import PickLogin from "./PickLogin";
import HowToWin from "./HowToWin";
import * as actions from "../../../store/actions";
import { connect } from 'react-redux';
import ReactGA from "react-ga";

class Pick3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            component: '',
            active: "play"
        };
    }
    selectComponent=(event)=>{
        event.preventDefault();
        this.setState({component: event.target.name, active: event.target.name});
    }
    componentDidMount() {
        console.log("scrolling up?")
        window.scrollTo(0,0);
        ReactGA.pageview('/pick3');
    }
    render() {
        let loggedin = false;
        if (this.props.isAuthenticated) {
            loggedin = true;
        }
        let toRender = <PickLogin/>
        if (loggedin) {
            toRender = <PickPlay/>;
        }
        let active= 'play';
        switch(this.state.component)
        {
            case "play":
                if (!loggedin) {
                    toRender = <PickLogin/>;
                } else {
                    toRender = <PickPlay/>;
                }

                active = "play";
                break;
            case "howtoplay":
                toRender = <HowToPlay/>;
                active = "howtoplay";
                break;
            case "purchases":
                toRender = <Purchases/>;
                active = "purchases";
                break;
            case "winnings":
                toRender = <Winnings/>;
                active = "winnings";
                break;
            case "winners":
                toRender = <Winners/>;
                active = "winners";
                break;
            case "howtowin":
                toRender = <HowToWin/>;
                active = "howtowim";
                break;
        }
        return (
            <div className="mojabet-website">
                <Header/>
                <div className="game-container">
                    <Row className="row-eq-height">
                        <Col col="lg-3 md-12 12" className="sidemenu-container">
                            <PickSideMenu balance={this.props.wallet} loggedIn={loggedin}  onClick = {this.selectComponent} active={this.state.active}/>
                        </Col>
                        <Col col="lg-9 md-12 12" className="pick3-game-container">
                            <div className="game">
                                {toRender}
                            </div>
                        </Col>
                    </Row>
                </div>
                <Footer/>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        wallet: state.auth.wallet,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch( actions.authCheckState() ),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( Pick3 );

