import axios from 'axios';
import * as actionTypes from './actionTypes';
import Stomp from "stompjs";
import i18next from "i18next";
import {
    logoutApi, purchaseInstalottoApi, purchasePick3Api, changeMsisdnApi,
    verifyMsisdnApi, registerApi, authCheckStateApi, getWalletApi,
    getScratchApi, buyScratchApi, playTicketApi, scratchApi, authApi, externalLogin, extLogin, extRenew, extAuth, extLogout
} from '../../General/Api';

//used to add spinner
export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const updateStomp = (stompConnected) => {
    return {
        type: actionTypes.STOMP_CONNECTED,
        stompConnected
    };
};

export const getDuplicateSession = (duplicate) => {
    return {
        type: actionTypes.GET_DUPLICATE_SESSION,
        duplicateSession: duplicate
    };
};

export const ext_Auth = (auth) => {
    return {
        type: actionTypes.EXT_AUTH,
        extAuthentication: auth
    };
};

export const authStart2 = () => {
    return {
        type: actionTypes.AUTH_START2
    };
};
export const authVerStart = () => {
    return {
        type: actionTypes.VER_START
    };
};
export const authVerFail = (message) => {
    return {
        type: actionTypes.VER_FAIL,
        vererror: message
    };
};
export const regStart = () => {
    return {
        type: actionTypes.REG_START
    };
};
export const authLogout = (state, action) => {
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const displayWarning = (message) => {
    return {
        type: actionTypes.DISPLAY_WARNING,
        warning: message
    }
}
export const displaySuccess = (message) => {
    return {
        type: actionTypes.DISPLAY_WARNING,
        successMessage: message
    }
}
export const authSuccess = (token) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        isAuthenticated: true
    };
};

export const updateMessage = (message, status) => {
    return {
        type: actionTypes.UPDATE_MESSAGE,
        ticketMessage: message,
        status: status
    };
};
export const updateSessionId = (session, status) => {
    console.log('session in auth', session, status)
    return {
        type: actionTypes.UPDATE_SESSION,
        session_id: session,
    };
};
export const updateMessageAF = (message, status) => {
    return {
        type: actionTypes.UPDATE_MESSAGE_AF,
        ticketMessageAF: message,
        statusAF: status
    };
};
// export const clearAllMessages = () => {
//     return {
//         type: actionTypes.CLEAR_MESSAGES,
//         ticketMessage: null,
//         status
//         ticketMessageAF
//         statusAF
//         ticketMessageAE
//         statusAE
//         statusSW
//         ticketMessageSW
//         ticketMessageBR
//         statusBR
//     };
// }

export const updateMessageAE = (message, status) => {
    return {
        type: actionTypes.UPDATE_MESSAGE_AE,
        ticketMessageAE: message,
        statusAE: status
    };
};
export const updateMessageBR = (message, status) => {
    return {
        type: actionTypes.UPDATE_MESSAGE_BR,
        ticketMessageBR: message,
        statusBR: status
    };
};
export const updateMessageSW = (message, status) => {
    return {
        type: actionTypes.UPDATE_MESSAGE_SW,
        ticketMessageSW: message,
        statusSW: status
    };
};
export const updateMessageL7 = (message, status) => {
    return {
        type: actionTypes.UPDATE_MESSAGE_L7,
        ticketMessageL7: message,
        statusL7: status
    };
};
export const updateMessageBa = (message, status) => {
    return {
        type: actionTypes.UPDATE_MESSAGE_BA,
        ticketMessageBa: message,
        statusBa: status
    };
};
export const updateMessageSc = (message, status) => {
    return {
        type: actionTypes.UPDATE_MESSAGE_SC,
        ticketMessageSc: message,
        statusSc: status
    };
};
export const updatePickMessage = (message, status) => {
    console.log('hon', message)
    return {
        type: actionTypes.UPDATE_PICK_MESSAGE,
        pick3Message: message,
        status: status
    };
};
export const updateWallet = (wallet, promoWallet) => {
    //wallet===total
    //prmowallet === balance
    console.log('hhh')
    return {
        type: actionTypes.UPDATE_WALLET,
        wallet: wallet,
        promoWallet: promoWallet
    };
};
export const setUserMsisdn = (userMsisdn) => {
    return {
        type: actionTypes.SET_USER_MSISDN,
        userMsisdn: userMsisdn,
    }
}
export const setScratch = (gameId, maxPrize, prizeValues, ticektPrice) => {
    return {
        type: actionTypes.SET_SCRATCH,
        afid: gameId,
        af_maxPrize: maxPrize,
        af_prizeValues: prizeValues,
        af_ticketPrice: ticektPrice
    };
};
export const setAfricanExplorer = (gameId, maxPrize, prizeValues, ticektPrice) => {
    return {
        type: actionTypes.SET_AFRICAN_EXPLORER,
        aeid: gameId,
        ae_maxPrize: maxPrize,
        ae_prizeValues: prizeValues,
        ae_ticketPrice: ticektPrice
    };
};
export const setBrokenRecord = (gameId, maxPrize, prizeValues, ticektPrice) => {
    return {
        type: actionTypes.SET_BROKEN_RECORD,
        brid: gameId,
        br_maxPrize: maxPrize,
        br_prizeValues: prizeValues,
        br_ticketPrice: ticektPrice
    };
};
export const setSpinWin = (gameId, maxPrize, prizeValues, ticektPrice) => {
    return {
        type: actionTypes.SET_SPIN_WIN,
        swid: gameId,
        sw_maxPrize: maxPrize,
        sw_prizeValues: prizeValues,
        sw_ticketPrice: ticektPrice
    };
};
export const setLucky7 = (gameId, maxPrize, prizeValues, ticektPrice) => {
    return {
        type: actionTypes.SET_LUCKY7,
        l7id: gameId,
        l7_maxPrize: maxPrize,
        l7_prizeValues: prizeValues,
        l7_ticketPrice: ticektPrice
    };
};
export const setBingo = (gameId, maxPrize, prizeValues, ticektPrice) => {
    return {
        type: actionTypes.SET_BINGO,
        baid: gameId,
        ba_maxPrize: maxPrize,
        ba_prizeValues: prizeValues,
        ba_ticketPrice: ticektPrice
    };
};
export const setSport = (gameId, maxPrize, prizeValues, ticektPrice) => {
    console.log('ticektPrice', ticektPrice);
    return {
        type: actionTypes.SET_SPORT,
        scid: gameId,
        sc_maxPrize: maxPrize,
        sc_prizeValues: prizeValues,
        sc_ticketPrice: ticektPrice
    };
};
export const setUnplayed = (ticketNb) => {
    return {
        type: actionTypes.SET_UNPLAYED,
        unplayed_scratch: ticketNb,
    };
};
export const setUnplayedLucky7 = (ticketNb) => {
    return {
        type: actionTypes.SET_UNPLAYED_LUCKY7,
        unplayed_lucky7: ticketNb,
    };
};
export const setUnplayedBingo = (ticketNb) => {
    return {
        type: actionTypes.SET_UNPLAYED_BINGO,
        unplayed_bingo: ticketNb,
    };
};
export const setUnplayedSport = (ticketNb) => {
    return {
        type: actionTypes.SET_UNPLAYED_SPORT,
        unplayed_sport: ticketNb,
    };
};
export const sendPlayNot = () => {
    return {
        type: actionTypes.SEND_PLAY_NOT,
        l7play: 'play'
    }
};
export const setUnplayedAfricanExplorer = (ticketNb) => {
    return {
        type: actionTypes.SET_UNPLAYED_AFRICAN_EXPLORER,
        unplayed_africanExplorer: ticketNb,
    };
};
export const setUnplayedBrokenRecord = (ticketNb) => {
    return {
        type: actionTypes.SET_UNPLAYED_BROKEN_RECORD,
        unplayed_brokenRecord: ticketNb,
    };
};
export const setUnplayedSpinWin = (ticketNb) => {
    return {
        type: actionTypes.SET_UNPLAYED_SPIN_WIN,
        unplayed_spinwin: ticketNb,
    };
};
export const verifySuccess = (token) => {
    return {
        type: actionTypes.VERIFY_SUCCESS,
        token: token
    };
};
export const registerSuccess = (verify) => {
    return {
        type: actionTypes.REGISTER_SUCCESS,
        verify: verify
    };
}

export const redirectVerify = (redirect) => {
    return {
        type: actionTypes.REDIRECT_VERIFY,
        redirectVerify: redirect
    };
}
export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};
export const authFail2 = (error) => {
    return {
        type: actionTypes.AUTH_FAIL2,
        error2: error
    };
};
export const authRegFail = (error) => {
    return {
        type: actionTypes.AUTH_REG_FAIL,
        regerror: error
    };
};
export const logout = () => {
    let token = localStorage.getItem('token');
    return dispatch => {
        const authData = {
            token: token,
        };
        let url = logoutApi(token, i18next.language)

        axios.delete(url, authData)
            .then(response => {
                localStorage.removeItem('token');
                localStorage.removeItem('Balance');
                localStorage.removeItem('Promo_Balance');
                localStorage.removeItem('msisdn');
                // localStorage.removeItem('nickname');
                localStorage.removeItem('operator');
                dispatch(authLogout());
            })
            .catch(err => {
                console.log(err);
                dispatch(authLogout());
            });
    };
};

export const purchaseInstalotto = (ticketsNb, token) => {
    return dispatch => {
        const authData = {
            numberOfTickets: ticketsNb,
            token: token,
        };
        // dispatch(authStart());
        let url = purchaseInstalottoApi(i18next.language);
        axios.post(url, authData)
            .then(response => {
                if (response.data.status === 1) {
                    dispatch(updateMessage(response.data.message, 1));
                    dispatch(getWallet())
                } else {
                    console.log('lalalala')
                    dispatch(updateMessage(response.data.message, response.data.status));
                }
                // dispatch(authSuccess());
            })
            .catch(err => {
                dispatch(authFail(err.message));
            });
    };
};
export const purchasePick3 = (stake, pickNumbers, token) => {
    return dispatch => {
        const authData = {
            stake: stake,
            numbers: pickNumbers,
            token: token,
        };
        let url = purchasePick3Api();
        // dispatch(authStart());
        axios.post('/pick-three-api/tickets?isWallet=true', authData)
            .then(response => {
                if (response.data.status === 1) {
                    dispatch(updatePickMessage(response.data.message, 1));
                    dispatch(getWallet());
                } else {
                    console.log(response.data.message);
                    dispatch(updatePickMessage(response.data.message, 0));
                }

            })
            .catch(err => {
                // dispatch(authFail(err.message));
            });
    };
};
//logout
export const endSession = () => {
    console.log('end session')
    return dispatch => {
        console.log('hhh')
        const session = localStorage.getItem('session_id');
        axios.delete("/external-user-api/sessions/" + session)
            .then((resp => {
                console.log("deleteeeeee ", resp);
                localStorage.clear();
                dispatch(ext_Auth(false))
                window.location.reload();

            }));
    };
}

export const stompConnect = () => {

    return dispatch => {
        const ws = new WebSocket(process.env.REACT_APP_WEBSOCKET);

        ws.onopen = function (e) {
            console.log("Connection to server opened", ws);

            const headers = {
                login: process.env.REACT_APP_SOCKET_USERNAME,
                passcode: process.env.REACT_APP_SOCKET_PASCODE,
                AccessKey: process.env.REACT_APP_SOCKET_ACCESS_KEY,
            };
            var frame =
                "CONNECT\n" +
                `login:${process.env.REACT_APP_STOMP_USERNAME}\n` +
                `passcode:${process.env.REACT_APP_STOMP_PASCODE}\n` +
                "accept-version:1.1,1.0\n" +
                "nickname: anonymous\n" +
                "heart-beat:10000,10000\n" +
                "\n\n\0";

            ws.send(frame);

            let stompClient = Stomp.over(ws);

            stompClient.connect(headers, function (frame) {
                console.log("Connected----", stompClient);
                dispatch(updateStomp(true));
                stompClient.subscribe(
                    "/exchange/flexbetsgcj/flexbet.agent." + localStorage.getItem('account_code'),
                    function (message) {

                        let body = JSON.parse(message.body);
                        console.log("stomp", message.body);
                        console.log("isForced", body.data.isForced);
                        console.log('sessioooon coming', body.data.session);
                        console.log('local', localStorage.getItem('session_id'));
                        // setTimeout(function() {
                        //forced = true kick out
                        if (body.data.isExpired && body.data.session == localStorage.getItem('session_id') && !body.data.isForced) {
                            console.log('renew')
                            dispatch(renewSession(true));

                        }

                        if (body.data.session === localStorage.getItem('session_id') && body.data.isForced) {
                            console.log('forced')
                            dispatch(endSession());
                        }
                        // }, 2000);
                    }
                );
            });
        };

        ws.onmessage = (message) => {
            console.log("--", message.data);
        };

        ws.onerror = (err) => {
            console.log("ws error: ", err)
        }

        ws.onclose = (err) => {
            console.log("ws onclose: ", err)
        }
    }
}

export const responseHandling = async (response, dispatch) => {

    console.log("login response: ", response)
    if (response.data.status == 1) {

        localStorage.removeItem('unverified_token');
        localStorage.removeItem('unverified_msisdn');
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('session_id', response.data.external_session_id);
        localStorage.setItem('operator', response.data.userData.opr);
        localStorage.setItem('msisdn', response.data.userData.login);
        localStorage.setItem('username', response.data.userData.phone_number);
        localStorage.setItem('customer_id', response.data.userData.customer_ID);
        localStorage.setItem('account_code', response.data.userData.account_code);
        // localStorage.setItem('Balance', response.data.userData.balances.total);
        // localStorage.setItem('winningAmmount', response.data.userData.balances.winnings);
        let x = response.data.userData.phone_number.replace('+', '');
        dispatch(setUserMsisdn(x))
        dispatch(updateSessionId(response.data.external_session_id, 'responseHandling'));
        dispatch(updateWallet(response.data.userData.balances.total, response.data.userData.balances.winnings));
        dispatch(getDuplicateSession(false));
        dispatch(redirectVerify(false));
        dispatch(authSuccess(response.data.token));
        // dispatch(getWallet())
        dispatch(ext_Auth(true))

    }
    else if (response.data.status == 0) {
        // endSession();
        dispatch(authFail(response.data.message));
    }

    else if (response.data.status === 3) {
        // localStorage.removeItem('Balance');
        // localStorage.removeItem('token');
        // localStorage.removeItem('msisdn');
        // localStorage.setItem('unverified_token', response.data.token);
        // // localStorage.setItem('operator', response.data.user.mobileNumber.operator);
        // localStorage.setItem('unverified_msisdn', response.data.user.mobileNumber.value);

        // dispatch(redirectVerify(true));
    }
    else if (response.data.status === 2) {// more than one seesion exists
        localStorage.setItem('customer_id', response.data.userData.customer_ID);
        dispatch(getDuplicateSession(true));
        dispatch(authFail("Vous avez une connexion active"));
    }
    else if (response.data.status === 11) {
        dispatch(authFail(response.data.message));
    }
    else if (response.data.status === 8) {
        dispatch(authFail(response.data.message));
    }

}

//every time a user clicks it renews
export const renewSession = (renew) => {

    return dispatch => {


        const customerId = localStorage.getItem('customer_id');

        if (renew) {
            axios.post(extRenew(customerId, 3))
                .then(response => {
                    if (response.data.status == 1) {

                        localStorage.removeItem('unverified_token');
                        localStorage.removeItem('unverified_msisdn');
                        localStorage.setItem('token', response.data.token);
                        localStorage.setItem('session_id', response.data.external_session_id);
                        localStorage.setItem('operator', response.data.userData.opr);
                        localStorage.setItem('msisdn', response.data.userData.login);
                        localStorage.setItem('username', response.data.userData.phone_number);
                        localStorage.setItem('customer_id', response.data.userData.customer_ID);
                        localStorage.setItem('account_code', response.data.userData.account_code);
                        let x = response.data.userData.phone_number.replace('+', '');
                        dispatch(setUserMsisdn(x))

                        // localStorage.setItem('Balance', response.data.userData.balances.total);
                        // localStorage.setItem('winningAmmount', response.data.userData.balances.winnings);
                        dispatch(updateSessionId(response.data.external_session_id, 'renewSession'));
                        dispatch(updateWallet(response.data.userData.balances.total, response.data.userData.balances.winnings));
                        dispatch(getDuplicateSession(false));
                        dispatch(redirectVerify(false));
                        dispatch(authSuccess(response.data.token));
                        // dispatch(getWallet())
                        dispatch(ext_Auth(true))

                    }
                    else if (response.data.status == 0) {
                        // endSession();
                        dispatch(authFail(response.data.message));
                    }

                    else if (response.data.status === 3) {
                        // localStorage.removeItem('Balance');
                        // localStorage.removeItem('token');
                        // localStorage.removeItem('msisdn');
                        // localStorage.setItem('unverified_token', response.data.token);
                        // // localStorage.setItem('operator', response.data.user.mobileNumber.operator);
                        // localStorage.setItem('unverified_msisdn', response.data.user.mobileNumber.value);

                        // dispatch(redirectVerify(true));
                    }
                    else if (response.data.status === 2) {// more than one seesion exists
                        localStorage.setItem('customer_id', response.data.userData.customer_ID);
                        dispatch(getDuplicateSession(true));
                        dispatch(authFail("Vous avez une connexion active"));
                    }
                    else if (response.data.status === 11) {
                        dispatch(authFail(response.data.message));
                    }
                    else if (response.data.status === 8) {
                        dispatch(authFail(response.data.message));
                    }

                })
        } else dispatch(getDuplicateSession(false));


    };
}

//when the session token is given from the url
export const externalAuth = (session) => {
    return dispatch => {

        axios.post(extAuth(session))
            .then(response => {
                // if (response.data.status === 1) 
                // dispatch(stompConnect());
                responseHandling(response, dispatch);

            })

    };
}

export const auth = (username, password) => {
    return dispatch => {
        dispatch(authStart());


        const externalAuthentication = true;
        if (externalAuthentication) {
            console.log("external authentication");
            const authData = {
                type: 3,
                password: password,
            };
            let url = extLogin(username);

            axios.post(url, authData)
                .then(response => {
                    // if (response.data.status === 1)
                    responseHandling(response, dispatch);
                })
                .catch(err => {
                    console.log(err);
                });

        } else {
            console.log("! external authentication")

            const authData = {
                type: 1,
                password: password,
            };
            let url = authApi(username, i18next.language);

            axios.post(url, authData)
                .then(response => {
                    console.log("?????????", response)
                    if (response.data.status === 1) {
                        localStorage.removeItem('unverified_token');
                        localStorage.removeItem('unverified_msisdn');
                        localStorage.setItem('token', response.data.token);
                        localStorage.setItem('operator', response.data.user.mobileNumber.operator);
                        localStorage.setItem('msisdn', response.data.user.mobileNumber.value);
                        let x = response.data.userData.phone_number.replace('+', '');
                        dispatch(setUserMsisdn(x))
                        dispatch(redirectVerify(false));
                        dispatch(authSuccess(response.data.token));
                        dispatch(getWallet())
                    }
                    else if (response.data.status === 0) {
                        dispatch(authFail(response.data.message));
                    }

                    else if (response.data.status === 3) {
                        localStorage.removeItem('Balance');
                        localStorage.removeItem('token');
                        localStorage.removeItem('msisdn');
                        localStorage.setItem('unverified_token', response.data.token);
                        localStorage.setItem('operator', response.data.user.mobileNumber.operator);
                        localStorage.setItem('unverified_msisdn', response.data.user.mobileNumber.value);

                        dispatch(redirectVerify(true));
                    }
                    else if (response.data.status === 2) {
                        dispatch(authFail(response.data.message));
                    }
                    else if (response.data.status === 11) {
                        dispatch(authFail(response.data.message));
                    }
                    else if (response.data.status === 8) {
                        dispatch(authFail(response.data.message));
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }
    };
};

export const changeMsisdn = (msisdn) => {

    return dispatch => {

        const authData = {
            token: localStorage.getItem('token'),
            newMsisdn: msisdn,
        };
        let url = changeMsisdnApi(i18next.language);
        dispatch(authStart());
        axios.post(url, authData)
            .then(response => {
                console.log("respons change MSISDN", response)
                if (response.data.status === 1) {
                    localStorage.setItem('msisdn', msisdn);
                    dispatch(redirectVerify(true));
                    dispatch(authSuccess());
                    dispatch(setUserMsisdn(msisdn))
                }
                else if (response.data.status === 0) {
                    dispatch(authFail(response.data.message));
                }

                else if (response.data.status === 3) {
                    dispatch(redirectVerify(true));
                }
                else if (response.data.status === 2) {
                    dispatch(displayWarning(response.data.message));
                }
            })
            .catch(err => {
                dispatch(authFail());
            });
    };
};

export const verifyMsisdn = (verificationCode, token) => {
    if (token === undefined) {
        token = null;
    }
    return dispatch => {
        const authData = {
            mobileNumber: localStorage.getItem('unverified_msisdn'),
            code: verificationCode,
            token: token,
            type: 1
        };

        let existingToken = localStorage.getItem('unverified_token') != null ? localStorage.getItem('unverified_token') : localStorage.getItem('token');
        let url = verifyMsisdnApi(existingToken, i18next.language);

        dispatch(authVerStart());
        axios.patch(url, authData)
            .then(response => {
                console.log('veriy', response)
                if (response.data.status == 0) {
                    dispatch(authVerFail(response.data.message));
                } else {
                    if (response.data.status == 1) {
                        localStorage.setItem('token', response.data.token);
                        // localStorage.setItem('nickname', response.data.user.Nickname);
                        localStorage.setItem('msisdn', response.data.user.mobileNumbers[0]['value']);
                        let x = response.data.userData.phone_number.replace('+', '');
                        dispatch(setUserMsisdn(x))
                        localStorage.removeItem('unverified_msisdn');
                        localStorage.removeItem('unverified_token');
                        dispatch(verifySuccess(response.data.token));
                        dispatch(authSuccess(response.data.token));
                    }
                }
            })
            .catch(err => {
                console.log(err);
            });
    };
};
export const register = (username,
    msisdn,
    firstname,
    lastname,
    address,
    email,
    password,
    confirmPassword,
    dob,
    country,
    city,
    operator,
    maximumAmountPlayable,
    nationalityId,
    postalCode,
    nationalityIdExpiry,
    title,
    conditions) => {
    return dispatch => {
        dispatch(regStart());
        let con = false;
        if (conditions) {
            con = true;
        }
        let maxAmountPlayable = null;
        if (maximumAmountPlayable != '') {
            maxAmountPlayable = parseFloat(maximumAmountPlayable)
        }
        const authData = {
            username: username,
            mobileNumber: msisdn,
            firstName: firstname,
            lastName: lastname,
            address: address,
            email: email,
            password: password,
            confirmPassword: confirmPassword,
            dateOfBirth: dob,
            country: country,
            city: city,
            operator: operator,
            maximumAmountPlayable: maxAmountPlayable,
            nationalityId: nationalityId,
            postalCode: postalCode,
            nationalityIdExpiry: nationalityIdExpiry,
            title: title
        };
        let url = registerApi(i18next.language);

        axios.post(url, authData)
            .then(response => {
                if (response.data.status === 1) {
                    console.log('register', response);
                    localStorage.setItem('unverified_msisdn', msisdn);
                    localStorage.setItem('unverified_token', response.data.token);
                    dispatch(registerSuccess(true));
                }
                else if (response.data.status === 0) {
                    dispatch(authRegFail(response.data.message));
                }

                else if (response.data.status === 2) {
                    dispatch(displayWarning(response.data.message));
                }

            })
            .catch(err => {
                console.log(err);
            });
    };
};
export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        let url = authCheckStateApi(token);
        let status = null;
        if (token) {
            axios.get(url)
                .then(response => {
                    console.log('isAuth', response);
                    if (response.data.status == 1) {
                        dispatch(authSuccess(token));
                        dispatch(getWallet());
                    } else {
                        if (response.data.status == 0 || response.data.status == 4) {
                            dispatch(logout());
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        }
        else {
            // dispatch(logout());
        }
    };
};

//if renewSesion is false we don't make the api renew session call
export const getWallet = (renewSession) => {
    console.log('called')
    return dispatch => {


        const customerId = localStorage.getItem('session_id');

        let url = extAuth(customerId);
        if (customerId && renewSession != false)
            axios.post(url)
                .then(response => {

                    if (response.data.status == 1) {
                        console.log('wallettttt', response);
                        localStorage.setItem('token', response.data.token);
                        localStorage.setItem('session_id', response.data.external_session_id);
                        localStorage.setItem('operator', response.data.userData.opr);
                        localStorage.setItem('msisdn', response.data.userData.login);
                        localStorage.setItem('username', response.data.userData.phone_number);
                        let x = response.data.userData.phone_number.replace('+', '');
                        dispatch(setUserMsisdn(x))

                        localStorage.setItem('customer_id', response.data.userData.customer_ID);
                        // localStorage.setItem('Balance', response.data.userData.balances.total);
                        // localStorage.setItem('winningAmmount', response.data.userData.balances.winnings);
                        dispatch(updateSessionId(response.data.external_session_id, 'getWallet'))
                        dispatch(updateWallet(response.data.userData.balances.total, response.data.userData.balances.winnings));

                    }
                    else {
                        dispatch(endSession());
                    }
                    // responseHandling(response,dispatch)

                })
                .catch(function (error) {
                    console.log(error);
                });


    };
};
export const getUnplayed = (gameId) => {
    return dispatch => {
        let url = scratchApi(localStorage.getItem('token'), 2, 0, 1000, 0);

        axios.get(url)
            .then(response => {
                console.log('unplayed af', response)
                if (response.data.status == 1 && response.data.tickets != null) {
                    dispatch(setUnplayed(response.data.tickets.length))
                }
                else {
                    dispatch(setUnplayed(0))
                }
            })
            .catch(err => {
                console.log(err.message);
            });
    }
};
export const getUnplayedAfricanExplorer = (gameId) => {
    return dispatch => {

        let url = scratchApi(localStorage.getItem('token'), 6, 0, 1000, 0);
        axios.get(url)
            .then(response => {
                if (response.data.status == 1 && response.data.tickets != null) {
                    dispatch(setUnplayedAfricanExplorer(response.data.tickets.length))
                }
                else {
                    dispatch(setUnplayedAfricanExplorer(0))
                }
            })
            .catch(err => {
                console.log(err.message);
            });
    }
};
export const getUnplayedBrokenRecord = (gameId) => {
    return dispatch => {

        let url = scratchApi(localStorage.getItem('token'), 3, 0, 1000, 0);

        axios.get(url)
            .then(response => {
                if (response.data.status == 1 && response.data.tickets != null) {
                    dispatch(setUnplayedBrokenRecord(response.data.tickets.length))
                }
                else {
                    dispatch(setUnplayedBrokenRecord(0))
                }
            })
            .catch(err => {
                console.log(err.message);
            });
    }
};
export const getUnplayedSpinWin = (gameId) => {
    return dispatch => {

        let url = scratchApi(localStorage.getItem('token'), 5, 0, 1000, 0)

        axios.get(url)
            .then(response => {
                if (response.data.status == 1 && response.data.tickets != null) {
                    dispatch(setUnplayedSpinWin(response.data.tickets.length))
                }
                else {
                    dispatch(setUnplayedSpinWin(0))
                }
            })
            .catch(err => {
                console.log(err.message);
            });
    }
};
export const getUnplayedLucky7 = (gameId) => {
    return dispatch => {


        let url = scratchApi(localStorage.getItem('token'), 4, 0, 1000, 0);

        axios.get(url)
            .then(response => {
                if (response.data.status == 1 && response.data.tickets != null) {
                    dispatch(setUnplayedLucky7(response.data.tickets.length))
                }
                else {
                    dispatch(setUnplayedLucky7(0))
                }
            })
            .catch(err => {
                console.log(err.message);
            });
    }
};
export const getUnplayedBingo = (gameId) => {
    return dispatch => {

        let url = scratchApi(localStorage.getItem('token'), 7, 0, 1000, 0);
        axios.get(url)
            .then(response => {
                if (response.data.status == 1 && response.data.tickets != null) {
                    dispatch(setUnplayedBingo(response.data.tickets.length))
                }
                else {
                    dispatch(setUnplayedBingo(0))
                }
            })
            .catch(err => {
                console.log(err.message);
            });
    }
};
export const getUnplayedSport = (gameId) => {
    return dispatch => {

        let url = scratchApi(localStorage.getItem('token'), 8, 0, 1000, 0);

        axios.get(url)
            .then(response => {
                if (response.data.status == 1 && response.data.tickets != null) {
                    dispatch(setUnplayedSport(response.data.tickets.length))
                }
                else {
                    dispatch(setUnplayedSport(0))
                }
            })
            .catch(err => {
                console.log(err.message);
            });
    }
};
export const getScratch = (gametype) => {
    console.log('kamen alo');
    return dispatch => {

        let url = getScratchApi(gametype);

        axios.get(url)
            .then(response => {
                console.log('AAAAAA', response);
                if (response.data.status == 1) {
                    console.log('getscartch', response);
                    switch (gametype) {
                        case 2:
                            dispatch(setScratch(response.data.id, response.data.maxPrize, response.data.ticketPrices, response.data.price));
                            dispatch(getUnplayed(response.data.id));
                            break;
                        case 3:
                            dispatch(setBrokenRecord(response.data.id, response.data.maxPrize, response.data.ticketPrices, response.data.price));
                            dispatch(getUnplayedBrokenRecord(response.data.id));
                            break;
                        case 6:
                            dispatch(setAfricanExplorer(response.data.id, response.data.maxPrize, response.data.ticketPrices, response.data.price));
                            dispatch(getUnplayedAfricanExplorer(response.data.id));
                            break;
                        case 5:
                            dispatch(setSpinWin(response.data.id, response.data.maxPrize, response.data.ticketPrices, response.data.price));
                            dispatch(getUnplayedSpinWin(response.data.id));
                            break;
                        case 4:
                            dispatch(setLucky7(response.data.id, response.data.maxPrize, response.data.ticketPrices, response.data.price));
                            dispatch(getUnplayedLucky7(response.data.id));
                            break;
                        case 7:
                            dispatch(setBingo(response.data.id, response.data.maxPrize, response.data.ticketPrices, response.data.price));
                            dispatch(getUnplayedBingo(response.data.id));
                            break;
                        case 8:
                            dispatch(setSport(response.data.id, response.data.maxPrize, response.data.prizeValues, response.data.price));
                            dispatch(getUnplayedSport(response.data.id));
                            break;
                    }
                }
            })
            .catch(err => {
                console.log(err);
            });
    }
};
export const buyScratch = (gameId, number, type, scratchType) => {
    return dispatch => {

        const data = {
            token: localStorage.getItem('token'),
            type: scratchType,
            numberOfTickets: number,
            gameType: scratchType
        };

        let url = buyScratchApi(i18next.language);

        dispatch(authStart());
        axios.post(url, data)
            .then(response => {
                switch (type) {
                    case 'af':
                        dispatch(getUnplayed(gameId));
                        dispatch(updateMessageAF(response.data.message, response.data.status));
                        break;
                    case 'ae':
                        dispatch(getUnplayedAfricanExplorer(gameId));
                        dispatch(updateMessageAE(response.data.message, response.data.status));
                        break;
                    case 'sw':
                        dispatch(getUnplayedSpinWin(gameId));
                        dispatch(updateMessageSW(response.data.message, response.data.status));
                        break;
                    case 'br':
                        dispatch(getUnplayedBrokenRecord(gameId));
                        dispatch(updateMessageBR(response.data.message, response.data.status));
                        break;
                    case 'l7':
                        dispatch(getUnplayedLucky7(gameId));
                        dispatch(updateMessageL7(response.data.message, response.data.status));
                        break;
                    case 'ba':
                        dispatch(getUnplayedBingo(gameId));
                        dispatch(updateMessageBa(response.data.message, response.data.status));
                        break;
                    case 'sc':
                        dispatch(getUnplayedSport(gameId));
                        dispatch(updateMessageSc(response.data.message, response.data.status));
                        break;
                }
                dispatch(getWallet());
                if (response.data.status == 1) {

                    dispatch(authSuccess());
                } else {
                    // dispatch(updateMessage(response.data.messages, 0));
                    dispatch(authFail());
                }
            })
            .catch(err => {
                dispatch(updateMessage(err.message, 0));
                dispatch(authFail());
            });
    }
};
export const playTicket = (gameId, ticketId, serialNumber, scratchType) => {
    console.log('Played', ticketId);
    return dispatch => {
        let url = playTicketApi(i18next.language);

        let data = {
            token: localStorage.getItem('token'),
            ticketId: ticketId,
            gameType: scratchType,
        };
        axios.patch(url, data)
            .then(response => {
                console.log('play response', response);
                dispatch(getWallet(false));
                switch (scratchType) {
                    case 2:
                        dispatch(getUnplayed(gameId));
                        dispatch(updateMessageAF('', 99));
                        break;
                    case 3:
                        dispatch(getUnplayedBrokenRecord(gameId));
                        dispatch(updateMessageBR('', 99));
                        break;
                    case 4:
                        console.log('l7');
                        dispatch(getUnplayedLucky7(gameId));
                        dispatch(updateMessageL7('', 99));
                        dispatch(sendPlayNot());
                        break;
                    case 5:
                        dispatch(getUnplayedSpinWin(gameId));
                        dispatch(updateMessageSW('', 99));
                        break;
                    case 6:
                        dispatch(getUnplayedAfricanExplorer(gameId));
                        dispatch(updateMessageAE('', 99));
                        break;
                    case 7:
                        dispatch(getUnplayedBingo(gameId));
                        dispatch(updateMessageBa('', 99));
                        break;
                    case 8:
                        dispatch(getUnplayedSport(gameId));
                        dispatch(updateMessageSc('', 99));
                        break;
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};