import React, {Component} from 'react';
import Header from '../../Components/NewHeader/Header';
import Footer from "../../Components/Footer/Footer";
import { connect } from 'react-redux';
import Input from '../../Components/UI/Input/Input';
import * as actions from '../../store/actions/index';
import {Redirect} from "react-router-dom";
import { translate} from "react-i18next";
import ReactGA from "react-ga";
import i18next from "i18next";
import axios from "axios";
import { userMsisVerificationApi } from '../../General/Api';

class VerifyPhone extends Component {
    state = {
        resendLoading: false,
        resendMessage: null,
        controls: {
            code: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                },
                value: '',
                validation: {
                    required: true,
                },
                valid: false,
                touched: false,
                label: "Code de vérification"
            },

        },
    };
    componentWillMount() {
        window.scrollTo(0,0);
        ReactGA.pageview('/verify');
        this.setState({
            resendMessage: null
        })
    }
    checkValidity ( value, rules ) {
        let isValid = true;
        if ( !rules ) {
            return true;
        }

        if ( rules.required ) {
            isValid = value.trim() !== '' && isValid;
        }

        if ( rules.minLength ) {
            isValid = value.length >= rules.minLength && isValid
        }

        if ( rules.maxLength ) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if ( rules.isEmail ) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test( value ) && isValid
        }

        if ( rules.isNumeric ) {
            const pattern = /^\d+$/;
            isValid = pattern.test( value ) && isValid
        }

        return isValid;
    }
    inputChangedHandler = ( event, controlName ) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
                valid: this.checkValidity( event.target.value, this.state.controls[controlName].validation ),
                touched: true
            }
        };
        this.setState( { controls: updatedControls } );
    }
    resendCode = (event) => {
        event.preventDefault();
        console.log('resend');
        // this.props.onResendCode();
        this.setState({
            resendLoading: true,
        });
        const authData = {
            msisdn: localStorage.getItem('unverified_msisdn'),
        };
        
        let url = userMsisVerificationApi(localStorage.getItem('unverified_token'),localStorage.getItem('unverified_msisdn'),i18next.language);
       
        axios.put(url, authData)
        .then(response => {
            console.log('resendresponse',response);
            this.setState({
                resendLoading: false,
                resendMessage: <span className="resend-message">{response.data.message}</span>
            });
        })
        .catch(err => {
            this.setState({
                resendLoading: false,
                resendMessage: <span className="resend-message">{err.message}</span>
            });
            // dispatch(authFail(err.response));
            // dispatch(authFail());
        });
    };

    submitHandler = ( event ) => {
        event.preventDefault();
        let to = localStorage.getItem('unverified_token')?localStorage.getItem('unverified_token'):null;
        if (to==null && localStorage.getItem('token')) {
            to = localStorage.getItem('token');
        }
        console.log(this.state)
        this.props.onVerify( this.state.controls.code.value, to);
    }
    render() {
        const { t } = this.props;

        console.log("~~~~",this.props.hpRedirect,this.state )
        let verifyRedirect = null;
        if (this.props.hpRedirect ) {
            verifyRedirect = <Redirect to={{
                pathname: '/',
            }}
            />
        }

        const formElementsArray = [];
        for ( let key in this.state.controls ) {
            formElementsArray.push( {
                id: key,
                config: this.state.controls[key]
            } );
        }

        let form = formElementsArray.map( formElement => (
            <Input
                label={formElement.config.label}
                classes="inputfield"
                key={formElement.id}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                changed={( event ) => this.inputChangedHandler( event, formElement.id )} />
        ) );

        let loading = false;
        if (this.props.loading) {
            loading = <div className="loading-container" style={{marginTop: '30px'}}><i className="fa fa-spinner fa-spin"></i> </div>;
        }
        let resendloading = false;
        if (this.state.resendLoading) {
            resendloading = <div className="loading-container"><i className="fa fa-spinner fa-spin"></i> </div>;
        }
        let error = '';
        if (this.props.vererror) {
            error = (
                <div className="error">
                    <i className="fa fa-exclamation-triangle"></i>
                    <span>{this.props.vererror}</span>
                </div>
            );
        }
        return (

            <div className="mojabet-website verify">
                <Header/>
                {verifyRedirect}
                <section className="staticpages-content">
                    <div className="container-fluid content-container">
                        {this.props.hpRedirect}
                        <div className="page-title-container">
                            <h1 className="page-title">{t("Compte")}</h1>
                        </div>
                        <div className="form-registration verify-form">
                            <h2 className="form-title">{t("Vérifiez votre numéro de mobile")}</h2>
                            <span className="sub-title">{t("Un code de vérification sera envoyé à votre mobile par SMS")}</span>
                            <div className="form-container">
                                <form onSubmit={this.submitHandler}>
                                    {form}
                                    <div className="resend-code">
                                        <span>{t("N'a pas reçu de code?!")}</span>
                                        <a className="resend-btn" href="#" onClick={this.resendCode}>{t("Renvoyer le code")}</a>
                                        {resendloading}
                                        {this.state.resendMessage}
                                    </div>
                                    <div className="next-back">
                                        <button btnType="Success"  class="next-btn">{t("Soumettre")}</button>
                                        {loading}
                                        {error}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        loading: state.auth.verloading,
        hpRedirect: state.auth.hpRedirect,
        vererror: state.auth.vererror,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onVerify: ( code, token ) => dispatch( actions.verifyMsisdn( code, token ) ),
        // onResendCode: ( ) => dispatch( actions.resendCode() ),
    };
};
export default connect( mapStateToProps, mapDispatchToProps )(translate("translations")( VerifyPhone ));
