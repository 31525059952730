import React from "react";
import Header from "../../Components/NewHeader/Header";
import Footer from "../../Components/Footer/Footer";
import Hero from "../../Components/Hero/Hero";
import TermsHero from "../../assets/images/termsHero.jpg";

export const JeuResponsable = () => {
    return (
        <div className="mojabet-website aboutus">
        <Header />
        <Hero background={TermsHero} />
        <section className="staticpages-content">
          <div className="container-fluid content-container">
            <div className="page-title-container">
              <h1 className="page-title">JEU RESPONSABLE</h1>
            </div>
            <div className="page-text greenbg">
            <p>
            La loi n° 2020-480 du 27 mai 2020 portant régime juridique des jeux du hasards en Côte d’Ivoire dispose des mesures appropriées à prendre pour protéger les joueurs contre la dépendance au jeu et l’engagement excessif de la fortune ou du revenu, notamment par : </p>
            <p style={{ paddingLeft: "2%"}}>  
                <p> <span>  -	l’information des joueurs ; </span> </p>
                <p> <span>  -	le repérage précoce des joueurs à risque ; </span> </p>
                <p> <span>  -	l’autocontrôle des joueurs, les limitations de jeu et les modérations de jeu ; </span> </p>
                <p> <span>  -	l’adoption et l’application de mesures d’exclusion ; </span> </p>
                <p> <span>  -	la formation et le perfectionnement de leurs employés au jeu responsable ; </span> </p>
                <p> <span>  -	la collecte des données nécessaires à l’évaluation de l’efficacité des mesures prises. </span> </p>
                <p> <span>  -	la promotion du jeu responsable. </span> </p>
            </p> 
            <p>    L’âge légal pour participer aux jeux de hasard est par ailleurs fixé à dix-huit ans révolus. Toutefois, ne peuvent accéder aux casinos et établissements de machines à sous que les personnes âgées de 21 ans révolus. </p>
             <p>   Le non-respect de ces dispositions par l’opérateur de jeux, est puni des peines prévues par la loi.</p>

             <p>   Le secteur des jeux du hasard est un secteur réglementé par l’Etat au regard des risques spécifiques qu’il comporte en termes de préservation de l’ordre public et social, en particulier s’agissant de la prévention du jeu des mineurs et des comportements de jeu excessif.</p>
            <p>    La lutte contre le jeu des mineurs qui reste une priorité de la LONACI se traduit par l’accompagnement du réseau de vente et la sensibilisation des populations d’une part, et du contrôle et de l’évaluation de l’application de l’interdiction de vente de jeu aux mineurs d’autre part.</p>
             <p>   Un point d’honneur est également porté à la prévention des comportements excessifs de jeu par la détection et l’accompagnement des personnes en situation de vulnérabilité.</p>

             <p>   Le jeu de loterie est une distraction mais doit rester une expérience divertissante parmi d’autres. Le jeux de la LONACI ne sont accessibles qu’aux personnes ayant l’âge légal requis et est en tout état de cause interdit aux mineurs. Nos conseils pratiques :</p>
             <p style={{ paddingLeft: "2%"}}> 
                <p> <span>  -	Jouez pour le plaisir ;</span> </p>
                <p> <span>  -	Jouez selon vos moyens sans emprunter de l’argent ;</span> </p>
                <p> <span>  -	Fixez-vous des limites de temps de jeux ;</span> </p>
                <p> <span>  -	Fixez-vous un budget avant de jouer ;</span> </p>
                <p> <span>  -	Retenez que le hasard reste le maître du jeu ;</span> </p>
                <p> <span>  -	Invitez les mineurs à jouer à d’autres formes de jeux pour les protéger.  </span> </p>
            </p>
          
           </div>
          </div>
        </section>
        <Footer />
      </div>
    )
}
