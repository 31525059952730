import React from 'react';
import Radium, { StyleRoot } from 'radium';

const Hero = (props) => {
    const styles = {
        width: "100%",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '40vh',
        backgroundImage :  `url(${props.background})`,
        backgroundPosition: 'center',
    }
    return (
        <StyleRoot>
            <div className="hero-section" style={styles}></div>
        </StyleRoot>
    );
}

export default Radium( Hero );
