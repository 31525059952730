import React, {Component} from 'react';
import axios from "axios";
import { pick3drawsApi } from '../../../General/Api';

const FilterableTable = require('react-filterable-table');

class Winners extends Component {
    state = {
        pick3data: [],
        loading: false,
    };
    componentDidMount () {

        let url = pick3drawsApi(true,0,100,localStorage.getItem('i18nextLng'));

        this.setState({loading: true})
        axios.get(url)
            .then(response => {
                console.log("response ",response)
                this.setState({
                    pick3data:response.data.draws,
                    loading: false,
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    render(){
        let fields = [
            { name: 'id', displayName: "Numéro Tirage", inputFilterable: true, sortable: true },
            { name: 'date', displayName: "Date", inputFilterable: true, exactFilterable: true, sortable: true },
            { name: 'winningNumbers', displayName: "Numéros", inputFilterable: true, exactFilterable: true, sortable: true },
        ];
        let loading = false;
        if (this.state.loading) {
            loading = <div className="loading-container overlay"><i className="fa fa-spinner fa-spin"></i> </div>;
        }
        let tablerows = null;
        if (this.state.pick3data) {
            tablerows = <FilterableTable
                namespace=""
                data={this.state.pick3data}
                fields={fields}
                noRecordsMessage={"Il n'y a aucun enregistrement à afficher"}
                noFilteredRecordsMessage={"Il n'y a aucun enregistrement à afficher"}
                pagerTitles={{ first: '<|', last: '>|' }}
                pageSize={20}
            />;
        }

        return (
            <div className="purchases-table loadable-block">
                {/*{loading}*/}
                {tablerows}


            </div>
        );
    }
}
export default (Winners);
