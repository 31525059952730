import React,{useState} from "react";
import axios from 'axios';
import classes from "./Footer.module.scss";
import JouonsLogo from "../../assets/operatorsImages/jouonsOperator.png";
import ParierLogo from "../../assets/operatorsImages/parierOperator.png";
import MoneyLogo from "../../assets/operatorsImages/moovMoney.png";
import MtnLogo from "../../assets/operatorsImages/mtnLogo.png";
import OrangeLogo from "../../assets/operatorsImages/orangeMoney.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {newsLetterApi} from '../../General/Api';
import Loader from "../Loader/Loader";
function Footer() {
  const [email,setEmail] = useState('');
  const [status,setStatus] = useState(null);
  const [message,setMessage] = useState('');
  const [loader,setLoader] = useState(null);
  const handleNewsLetter = (e) => {
    e.preventDefault();
    setStatus(null);
    const data = {email:email}
    let url = newsLetterApi;
    setLoader(<Loader/>)
    axios.post(url,data).then((response) => {
      setStatus(response.data.status)
      setLoader(null);
      if(response.data.status === 1) {
        setMessage(response.data.message);
      } else {
        setMessage(response.data.errorMessages[0]);
      }
    })
  }
  return (
    <footer className={classes.footer}>
        <div className={classes.operatorMediaContainer}>
          <div className={classes.operatorsImages}>
            <img
              src={JouonsLogo}
              alt="lonaci grattage"
              className={classes.logoJounos}
            />
            <img
              src={ParierLogo}
              alt="lonaci grattage"
              className={classes.logo}
            />
            <img
              src={MoneyLogo}
              alt="lonaci grattage"
              className={classes.logo}
            />
            <img src={MtnLogo} alt="lonaci grattage" className={classes.logo} />
            <img
              src={OrangeLogo}
              alt="lonaci grattage"
              className={classes.logo}
            />
          </div>
         <div className={classes.pagesContainer}>
          <span className={classes.titlePage}>pages</span>
          <Link to="/aboutus" className={classes.linktopage}>
          about us
          </Link>
          <Link to="/contactus" className={classes.linktopage}>
          contact us
          </Link>
         </div>
         
          <div className={classes.mediaWrapper}>
            <form onSubmit={handleNewsLetter}>
            <input 
              type="email"
              autocomplete="off"
              required
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={classes.inputType}
              placeholder="ADRESSE ÉLECTRONIQUE"
            />
            <div className={classes.mediaWrapperBtn}>
              <button type="submit" className={classes.sendBtn}>
                S'Abonner
              </button>
          <div className={classes.mediaCircles}>
              <a target="_blank" href="https://www.facebook.com/JeuxdeGrattageLonaci" className={classes.icon}>
                <i className='fa fa-facebook'></i>
              </a>
              <a target="_blank" href="https://www.instagram.com/jeuxdegrattagelonaci/" className={classes.icon}>
                <i className='fa fa-instagram'></i>
              </a>
          </div>
            </div>
            </form>
            {loader}
            {message}
          </div>
        </div>
        <div className={classes.footerText}>
          <span className={classes.textStyle}>
            ©2023 LONACI-GRATTAGE.TOUS LES DROITS SONT RÉSERVÉS
          </span>
          <div>
          <Link className={classes.textStyle} to="/terms">
            MENTIONS LEGALES
          </Link>{" . "}
          <Link className={classes.textStyle} to="/privacy">
            CONDITIONS GENERALES D’UTILISATION
          </Link>{" . "}
          <Link className={classes.textStyle} to="/jeu-responsable">
            JEU RESPONSABLE
          </Link>   
          </div>
        </div>
    </footer>
  );
}

export default Footer;
