import React, { Component } from "react";
import axios from "axios";
import {translate} from "react-i18next";
import { pick3tokensApi } from "../../../General/Api";
const FilterableTable = require('react-filterable-table');

class Purchases extends Component {
    async getPickWinnings() {
        let url=pick3tokensApi(localStorage.getItem('token'),0);
        const res = await axios(url);
        return await res.data.tickets;
    }

    constructor(...args) {
        super(...args);
        this.state = {
            pick3data: null,
            pick3Loading: false,
        };
    }
    componentDidMount() {
        this.setState({pick3Loading: true});
        (async () => {
            try {
                this.setState({pick3data: await this.getPickWinnings()});
                this.setState({pick3Loading: false});
            } catch (e) {
                this.setState({pick3Loading: false});
            }
        })();
    }
    render(){
        const { t } = this.props;
        const renderStatus = (props) => {
            let iconClass = 'fa fa-hourglass-start';
            let purchaseStatus = "En cours";
            if (props.value.toLowerCase() == 'paid') {
                iconClass = "fa fa-trophy";
                purchaseStatus = "Gagné";

            } else if (props.value.toLowerCase() == 'losing') {
                iconClass = "fa fa-thumbs-down";
                purchaseStatus = "Perdu";
            }
            return (
                <span className="status"><i className={iconClass}></i> {purchaseStatus}</span>
            );
        };
        let fields = [

            { name: 'ticketId', displayName: t("Référence "), inputFilterable: true, sortable: true },
            { name: 'date', displayName: t("Date "), inputFilterable: true, exactFilterable: true, sortable: true },
            {name: 'numbers', displayName: t("NUMÉROS "), inputFilterable: true, exactFilterable: true, sortable: true},
            { name: 'status', displayName: t("Status "), inputFilterable: true, exactFilterable: true, sortable: true, render: renderStatus },
        ];

        let purchases =this.state.pick3Data;

        let tablerows = null;
        if (this.state.pick3data) {
            tablerows = <FilterableTable
                namespace=""
                initialSort="id"
                data={this.state.pick3data}
                fields={fields}
                thClassName={"thClassName"}
                tdClassName={"tdClassName"}
                noRecordsMessage={"Il n'y a aucun enregistrement à afficher"}
                noFilteredRecordsMessage={"Il n'y a aucun enregistrement à afficher"}
                pagerTitles={{ first: '<|', last: '>|' }}
                pageSize={20}
            />;
        }

        let loading = false;
        if (this.state.pick3Loading) {
            loading = <div className="loading-container overlay"><i className="fa fa-spinner fa-spin"></i> </div>;
        }
        return (
            <div className="purchases-table loadable-block">
                {loading}
                {tablerows}
            </div>
        );
    }
}
export default translate("translations")(Purchases);
