import React, { Component } from "react";
import Header from "../../Components/NewHeader/Header";
import Footer from "../../Components/Footer/Footer";
import Hero from "../../Components/Hero/Hero";
import TermsHero from "../../assets/images/termsHero.jpg";
import ReactGA from "react-ga";
import axios from "axios";
import { termsandconditionApi } from "../../General/Api";

class Terms extends Component {
  state = {
    terms: null,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    ReactGA.pageview("/terms");
    let url =termsandconditionApi('fr') ;
    axios
      .get(url)
      .then((response) => {
        console.log(response);
        this.setState({
          terms: response.data.items[0].content,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  render() {
    return (
      <div className="mojabet-website aboutus">
        <Header />
        <Hero background={TermsHero} />
        <section className="staticpages-content">
          <div className="container-fluid content-container">
            <div className="page-title-container">
              <h1 className="page-title">MENTIONS LEGALES</h1>
            </div>
            <div className="page-text greenbg">
              <div dangerouslySetInnerHTML={{ __html: this.state.terms }} />
              {/* <p><span><b>A. Généralités</b></span></p>
              <p><span>1.	Il est recommandé de lire attentivement les présentes Conditions Générales avant de continuer de naviguer sur le site Web <b>www.lonaci-moja.ci</b> (le « <b>Site</b> »). En s'inscrivant sur le Site, le client accepte d'être lié par les présentes Conditions Générales qui s'appliquent également à tous les paris téléphoniques et paris ou jeux via les appareils mobiles, y compris les applications téléchargeables sur un appareil mobile (comme si les références à l'utilisation du Site Internet étaient des références à l'utilisation de nos paris téléphoniques et/ou de nos applications de paris sur appareils mobiles).</span></p>
              <p><span>2.	Les présentes Conditions Générales sont sujettes à des modifications pour diverses raisons (y compris pour s‘adapter aux variations légales et règlementaires). Toute modification sera publiée sur le Site. Les Conditions Générales les plus récentes sont disponibles sur le Site. Si un changement est inacceptable pour le client, il doit cesser d'utiliser le Site et/ou fermer son compte immédiatement. Si, toutefois, le client continue d'utiliser le Site après la date à laquelle les modifications apportées aux Conditions Générales sont entrées en vigueur, le client accepte ces modifications par le fait même.</span></p>
              <p><span>Le client est responsable de déterminer si l'accès et/ou l'utilisation du Site est conforme aux lois applicables dans la juridiction du pays où le client réside et de nous garantir que le jeu n'est pas illégal sur le territoire où le client réside.</span></p>
              <p><span><b>B. Clients admissibles</b></span></p>
              <p><span>3. Les employés et les anciens employés (ayant quitté l'entreprise depuis moins d’un (1) ans) de l'Opérateur, ses affiliés, ses promoteurs ou d'autres agences, ses partenaires média et les membres de leurs familles proches ne peuvent pas participer à MojaBet.</span></p>
              <p><span>4. Tous les candidats doivent être âgés de plus de l'âge légal en Côte D’Ivoire pour parier sur le Site. MojaBet se réserve le droit de demander des documents confirmant l'âge et de suspendre le compte du client jusqu'à ce que ces informations soient fournies.</span></p>
              <p><span>5. MojaBet se réserve le droit d'interdire ou de limiter l'accès à un client si elle considère que le client utilise le Site ou toute plate-forme ou canal MojaBet de manière frauduleuse, déloyale, à des fins illégales et/ou inappropriées ou que le client a délibérément triché.</span></p>
              <p><span><b>C. Procédures et réglages</b></span></p>
              <p><span>6. MojaBet se réserve le droit de refuser partiellement ou totalement tout pari demandé à sa seule discrétion. Tous les paris sont placés à sa seule discrétion et aux risques du client.</span></p>
              <p><span>7. Le client est responsable de s'assurer que les détails de tous les paris sont corrects. Après qu'un pari a été placé, il ne peut être annulé ou modifié par le client. MojaBet se réserve le droit d'annuler un pari à tout moment.</span></p>
              <p><span>8. MojaBet se réserve le droit d'annuler et/ou d'inverser toute transaction impliquant des fonds préalablement fixés pour un pari, soit au moment du pari, soit rétrospectivement.</span></p>
              <p><span>9. Les paris seront valables dès que le ticket de pari (émis par le Site ou l'une des plates-formes ou applications de MojaBet) aura été confirmé comme accepté par MojaBet. De même, les paris seront considérés comme valables lorsque le client aura reçu la confirmation du paiement du ticket de pari par MojaBet.</span></p>
              <p><span>10. MojaBet se réserve le droit d'établir des limites sur le montant d'argent que vous pouvez parier, ainsi que les gains maximums à obtenir sur chaque ticket de pari. De telles limites, en tout cas, n'affecteront pas nécessairement tous les jeux de la même manière. Il peut y avoir des exceptions.</span></p>
              <p><span>11. MojaBet se réserve le droit d'annuler un pari, un jeu ou un événement spécifique. De même, MojaBet se réserve le droit d'annuler tout pari sur un événement ou une section spécifique à tout moment et sans préavis, sans aucune indemnité.</span></p>
              <p><span>12. MojaBet se réserve le droit de retenir tout paiement et d'annuler tout pari, si nous avons la preuve que l'une des options qui suit s'est produit : (I) l'intégrité de l'événement a été remise en question ; (II) les prix ont été manipulés. La preuve de ce qui précède sera basée sur la taille, le volume et le modèle des paris placés avec MojaBet, par l'intermédiaire du Site ou de l'une de ses plates-formes ou canaux.</span></p>
              <p><span>13. L'heure annoncée pour l'événement sera l'heure de départ. Tout ticket de pari payé après l'heure de départ sera considéré comme non valide et le montant misé sur ce ticket de pari sera retourné dès que possible par MojaBet. Si, pour une raison quelconque, un ticket de pari est accepté par inadvertance après le début de l'événement (par échec technique ou humain), les paris seront maintenus à condition que le résultat final ne soit pas connu et que tout avantage matériel ait été obtenu au moment où le pari a été placé.</span></p>
              <p><span>14. MojaBet se réserve le droit d'annuler tout pari, gain ou perte, si le résultat de l'événement/marché concernant ce pari est déjà connu. Toutes les heures indiquées sur le Site ou sur toute plate-forme ou canal MojaBet seront à l'heure officielle de la Côte D’Ivoire, sauf indication contraire.</span></p>
              <p><span>15. Si, pour une raison quelconque, nous ne sommes pas en mesure de valider les résultats d'un jeu ou d'un événement particulier, tout ticket de pari affecté par ces résultats sera annulé. A cet égard, les informations fournies par l'organisme officiel compétent du sport ou du jeu (le cas échéant) seront concluantes.</span></p>
              <p><span>Le client assume les risques liés à l’utilisation de Site. Le Site décline toute responsabilité.</span></p>
              <p><span>La responsabilité du Site ne peut être engagée en cas de force majeure.</span></p>
              <p><span><b>D. Erreurs et réclamations</b></span></p>
              <p><span>16. MojaBet n'est pas responsable des erreurs sur les paris dues à l'échec de la transcription, de la transmission ou de l'évaluation des données. Aussi, MojaBet se réserve le droit de corriger des erreurs évidentes (même après la fin de l'événement), ainsi que de changer ou d'annuler les paris concernés.</span></p>
              <p><span>17. MojaBet n'est en aucun cas responsable des erreurs éventuelles lors de l'affichage des résultats sur son Site ou par l'une quelconque de ses plates-formes ou canaux, puisqu'il s'agit d'informations fournies uniquement à titre informatif sans statut officiel.</span></p>
              <p><span>18. En cas de plainte ou de litige dans une transaction actuelle ou passée, le client peut, en premier lieu, utiliser le contact MojaBet sur son Site ou sur l'une de ses plates-formes ou canaux. Si le litige ou la réclamation est maintenu, MojaBet renvoie le client aux lois du pays dans lequel elle opère et spécifiquement aux institutions responsables des activités de jeu.</span></p>
              <p><span><b>E. Finances</b></span></p>
              <p><span>19. MojaBet n’est pas un prestataire de services financiers.</span></p>
              <p><span>20. En déposant des fonds à l'un des services offerts par MojaBet, que ce soit pour le paiement direct d'un ticket de pari, ou pour effectuer des dépôts dans le portefeuille, le client accepte et nous acceptons de les conserver dans le seul but de les utiliser pour placer ses paris sportifs ou autres produits de jeux.</span></p>
              <p><span>21. Lorsque d'une demande de retrait et/ou un paiement de gros gains est effectué, il peut également être demandé au client d'envoyer une pièce d'identité valide prouvant son âge et son adresse. D'après la réglementation de la Côte D’Ivoire, MojaBet doit vérifier l'identité d'un client lorsqu’un retrait cumulé et/ou un paiement atteint un montant de 1000 000 CFA (ou d'une valeur équivalente dans une autre devise). Dans ce cas, le paiement ne sera pas traité tant que MojaBet n'aura pas reçu toutes les pièces d'identité demandées. Les pièces d'identité admissibles comprennent, mais ne se limitent pas à :</span></p>
              <p><span> •	copie d'une photographie valable d'une pièce d'identité, tel qu'un passeport ou un permis de conduire ;</span></p>
              <p><span> •	copie d'une facture récente confirmant le lieu de résidence telle qu'une facture d'électricité, attestation de résidence, etc. (important : la facture de service ne doit pas dater de plus de 3 mois) ;</span></p>
              <p><span> •	copie d'un relevé de compte d'une carte bancaire (le relevé de compte ne doit pas dater de plus de 3 mois).</span></p>
              <p><span>22. MojaBet va communiquer, sur son Site, plateformes et tous ses canaux, les méthodes de paiement disponibles pour effectuer les transactions dérivées des services offerts. Ces méthodes de paiement sont les seuls valables pour compléter toutes transactions financières. MojaBet se réserve le droit, en tous cas, d'utiliser d'autres méthodes de paiement, par exemple pour le paiement de prix, dans des circonstances exceptionnelles.</span></p>
              <p><span>23. Pour les méthodes de paiement nécessitant un titulaire de compte spécifique, les transactions ne devraient être effectuées qu'à partir d'un compte dont vous êtes le titulaire de compte désigné. Dans les cas où MojaBet découvre que le titulaire du compte diffère de celui contenu dans les fichiers de la société, MojaBet se réserve le droit d'annuler tout ticket de pari ou dépôt payé ainsi que tout gain éventuel. </span></p>
              <p><span><b>F. Réglementation sur l'argent mobile</b></span></p>
              <p><span>24. Conformément à la nouvelle réglementation de la banque centrale, le client doit s’enregistrer pour pouvoir accéder à la monnaie électronique versée par MTN Money, Orange Money et MOOV Money après avoir effectué une transaction. MojaBet, en ce qui concerne cette réglementation, ne paiera personne qui ne se conformera pas à cette nouvelle réglementation de la banque centrale et ne se fera pas enregistrer. MojaBet n’est responsable d'aucun dommage résultant d'un non-paiement dû à un non-respect de cette nouvelle réglementation.</span></p>
              <p><span>25. Les limites sur les dépôts et les retraits des comptes clients sont fixées conformément aux limites imposées par les opérateurs de téléphonie (MTN Money, Orange Money, MOOV Money) et la banque centrale.</span></p>
              <p><span><b>G. Propriété intellectuelle</b></span></p>
              <p><span>26. Le client reconnaît et accepte que tous les droits, marques, logos, signes, titres, intérêts et tout autre contenu du Site font l’objet d’une protection de la propriété intellectuelle et sont propriété absolue de MojaBet ou qu’il soit et dûment autorisés à en jouir. Toute utilisation de la propriété intellectuelle sans le consentement écrit préalable de MojaBet est interdite. Le client s’engage à ne pas copier, reproduire, transmettre, publier, exposer, distribuer, exploiter commercialement ou falsifier tout élément en lien avec la propriété intellectuelle de MojaBet de quelque manière que ce soit (et le client convient de ne pas aider un tiers à en faire de même).</span></p>
              <p><span>27. Le client reconnaît et accepte que les documents et informations contenus dans l'offre et sur le Site sont mis à sa disposition dans le cadre d'une utilisation personnelle et non commerciale. Toute autre utilisation de ces documents et informations est strictement interdite. </span></p>
              <p><span><b>H. Service clientèle</b></span></p>
              <p><span>28. Le client peut contacter le service clientèle à tout moment comme suit:</span></p>
              <p><span>Courriel : <a  href={'mailto:serviceclient@lonaci-moja.ci?'} >serviceclient@lonaci-moja.ci</a> ou <a  href={'mailto:support@lonaci-moja.ci?'}>support@lonaci-moja.ci</a></span></p>
              <p><span>MTN call center:  <a href="tel:555">555</a></span></p>
              <p><span>Orange call center: <a href="tel:0707">0707</a></span></p>
              <p><span>Moov Call Center : <a href="tel:1011">1011</a></span></p> */}

           </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default Terms;
